import React, {useState} from "react";
import {Link, usePage} from "@inertiajs/react";


function Header ({locale, menu}) {

	const [isOpenMenuMobile, setIsOpenMenuMobile] = useState(false);
	const toggleMenu = (isOpen) => {
        const status = isOpen === undefined ? isOpenMenuMobile : !isOpen;
		if ( status ) {
			document.body.classList.remove('scroll-lock');
			setIsOpenMenuMobile(false);
		} else {
			document.body.classList.add('scroll-lock');
			setIsOpenMenuMobile(true);
		}
	};

    function changePathFromLanguage() {
        const { url } = usePage();
        const pathParts = url.split('/');

        if (locale === 'fr') pathParts[1] = pathParts.length === 2 ? '' : 'en';
        else pathParts[1] = 'fr';

        return  pathParts.join('/');
    }

    changePathFromLanguage();

	return (
        <header className={'header container'}>
            <div className={'header__inner container__inner'}>
                <div className={'header__logo'}>
                    <Link href={`/${locale === 'fr' ? 'fr' : ''}`}>
                        <img src={require('@images/layout/header/logo.svg').default} alt="logo"/>
                    </Link>
                </div>

                <nav className={`header__menu ${isOpenMenuMobile ? 'is-on' : ''}`}>
                    <ul className={'header__menu-list'}>
                        {menu.map(item => (
                            <li key={item.id}>
                                {
                                    (item.url.split('/').length===1 && item.url.indexOf('#') !== -1)
                                        ?
                                        // anchor link
                                        <a href={item.url} onClick={()=>toggleMenu(false)}><span>{item.title}</span></a>
                                        :
                                        // route link
                                        <Link href={item.url}><span>{item.title}</span></Link>
                                }

                            </li>
                        ))}
                    </ul>
                </nav>

                <div className={'header__lang-switcher'}>
                    <a
                        href={changePathFromLanguage()}
                        className={`header__lang-switcher-item ${locale === 'en' ? 'is-on' : ''}`}>
                        Eng
                    </a>
                    <span>|</span>
                    <a
                        href={changePathFromLanguage()}
                        className={`header__lang-switcher-item ${locale === 'fr' ? 'is-on' : ''}`}>
                        Fr
                    </a>
                </div>

                <button className={`header__btn-burger ${isOpenMenuMobile ? 'is-on' : ''}`} onClick={()=>toggleMenu()}>
                    <span className={'header__btn-burger-item header__btn-burger-item-1'}></span>
                    <span className={'header__btn-burger-item header__btn-burger-item-2'}></span>
                    <span className={'header__btn-burger-item header__btn-burger-item-3'}></span>
                </button>


            </div>
        </header>
	);
}

export default Header;
